
import { useReducer, useState, useEffect } from 'react';

import { Button, Chip, Divider, MenuItem, Typography } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import InputMask from 'react-input-mask';
import { FormField } from '../StyledComponents/FormField';
import TextLanguage from '../data/TextLanguage.json';

import dayjs from 'dayjs';
import { useCreatePatientMutation } from '../../hooks/useCreatePatientMutation';

import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import { useQueryClient } from '@tanstack/react-query';
import { ArrowRightIcon } from '@mui/x-date-pickers';

const LDMVariableMap = {
    'Name Sorname': 'name',
    'Ref No': 'LDMCaseNo',
    'Date of Admission': 'admissionDate',
    'Date of birth': 'dob',
    'Mobile Phone': 'mobilePhone',
    'Hotel / Region': 'LDMHotelLabel',
    'Room': 'hotelRoom',
    'IC / NO': 'InsuranceCo',
    'Policy No :': 'policyNo',
    'Franchise': 'franchise',
    'Complaints': 'complaints',
    'Note': 'notes'
};

const LDMInsuranceCoMap = {
    "Insurance company eb": "ЕВРОИНС",
    "Insurance company erv": "ERV_Ukraine",
    "Insurance company \"RESO-GARANTIA\"": "Reso-Garant",
    "Insurance company Pegas": "Pegas_GPI",
    "Insurance company ck": "СK_NEO",
    "Insurance company nmd": "Nomad_Kazakhstan",
    "Insurance company ge": "Gelios",
    "Insurance company nmb": "Nomad_Bishkek",
    "Insurance company nmbb": "Nomad_Bishkek",
    "Insurance company nmdarm": "Nomad_Armenia",
    "Insurance company alf": "Alfa",
};
const LDMFranchiseMap = {
    '25,00 USD': '25$',
    '30,00 USD': '30$',
    '25,00 EUR': '25€',
    '30,00 EUR': '30€',
};


function reducer(state, action) {
    switch (action.type) {
        case 'SET_FIELD':
            return {
                ...state,
                [action.fieldName]: action.payload,
            };

        case 'REPLACE':
            return {
                ...action.tempval
            };
        default:
            return state;
    }
}


const initialStatePatient = {
    userIDInsert: '',
    name: '',
    dob: '',
    admissionDate: '',
    mobilePhone: '',
    InsuranceCo: '',
    policyNo: '',
    franchise: '',
    hotelRoom: '',
    notes: '',
    complaints: '',
    InsurancePeriod: '',// (DD.MM-DD.MM)YYYY
};

const currencies = [
    {
        value: '25$',
        label: '25 $',
    },
    {
        value: '30$',
        label: '30 $',
    },
    {
        value: '25€',
        label: '25 €',
    },
    {
        value: '30€',
        label: '30 €',
    },
];



export const CreatePatientForm = ({
    selectedValue,
    setFormModalOpen,
    setsnackbarStatus,
    lang
}) => {

    // residency, city
    // policy date


    const [patientForm, dispatchPatient] = useReducer(reducer, initialStatePatient);

    const queryClient = useQueryClient();

    const InsuranceCompanyListData = queryClient.getQueryData(['insCoList']);
    const HospitalUserIDData = queryClient.getQueryData(['hospitalData']);

    useEffect(() => {
        dispatchPatient({
            type: 'SET_FIELD',
            fieldName: 'hotel',
            payload: selectedValue?.Name,
        });
    }, []);

    const handleFieldChange = (fieldName) => (e) => {

        dispatchPatient({
            type: 'SET_FIELD',
            fieldName: fieldName,
            payload: e.target.value,
        });

    };

    const [dateTargetAdmission, setdateTargetAdmission] = useState(dayjs());
    const [dateTargetDob, setdateTargetDob] = useState(dayjs());
    const [dateTargetPolicyStart, setdateTargetPolicyStart] = useState(dayjs());
    const [dateTargetPolicyEnd, setdateTargetPolicyEnd] = useState(dayjs());



    const [uploadProgress, setUploadProgress] = useState(0);
    const [submissionState, setSubmissionState] = useState(false);


    useEffect(() => { handleDateChange('admissionDate')(dateTargetAdmission) }, [dateTargetAdmission])
    useEffect(() => { handleDateChange('dob')(dateTargetDob) }, [dateTargetDob])
    useEffect(() => { handleDateChange('InsurancePeriodStart')(dateTargetPolicyStart) }, [dateTargetPolicyStart])
    useEffect(() => { handleDateChange('InsurancePeriodEnd')(dateTargetPolicyEnd) }, [dateTargetPolicyEnd])


    const handleDateChange = (fieldName) => (date) => {
        let formatDate = new Date(date.$d);
        //formatDate = (formatDate.toLocaleString('en-GB').split(',')[0]);
        dispatchPatient({
            type: 'SET_FIELD',
            fieldName: fieldName,
            payload: formatDate,
        });
    };

    const createPatientMutation = useCreatePatientMutation({ setFormModalOpen });



    const handleSubmitPatient = async (e) => {
        e.preventDefault();
        setSubmissionState(true);
        // Validation

        if (patientForm.userIDInsert === '') {
            setsnackbarStatus({ open: true, message: 'Please select a Hospital/Clinic', severity: 'error' });
            return;
        } else if (patientForm.InsuranceCo === '') {
            setsnackbarStatus({ open: true, message: 'Please select an Insurance Company', severity: 'error' });
            return;
        } else if (patientForm.franchise === '') {
            setsnackbarStatus({ open: true, message: 'Please select Franchise Amount', severity: 'error' });
            return;
        } else if (patientForm.name === '') {
            setsnackbarStatus({ open: true, message: 'Please enter the Patient\'s Name', severity: 'error' });
            return;
        } else if (patientForm.policyNo === '') {
            setsnackbarStatus({ open: true, message: 'Please enter a Policy Number', severity: 'error' });
            return;
        }


        createPatientMutation.mutate({ patientForm });
    };

    const handlePasteClipboard = () => {
        navigator.clipboard.readText().then(text => {

            let validationStatus = { name: false, dob: false, admissionDate: false, InsuranceCo: false, franchise: false, policyNo: false, LDMCaseNo: false };

            const fullTextSplit = text.split('\n');
            const tableData = fullTextSplit.map(row => row.trim().split('\t'));
            const rows = tableData.filter(row => row.length === 2);
            console.log(rows, rows.length);
            if (rows.length > 0 && LDMVariableMap[rows[0][0]] === 'name') {
                validationStatus = {...validationStatus, name: true};
            }


            rows.forEach(row => {
                const key = LDMVariableMap[row[0]];
                const value = row[1];
                switch (key) {
                    case null:
                    case undefined:
                        setsnackbarStatus({ open: true, message: 'Invalid Data', severity: 'error' });
                        break;
                    case 'dob':
                        if (value !== null) {
                            const day = value.split('.')[0];
                            const month = value.split('.')[1];
                            const year = value.split('.')[2];
                            const parsedDate = new Date(`${year}-${month}-${day}`);
                            dispatchPatient({
                                type: 'SET_FIELD',
                                fieldName: 'dob',
                                payload: parsedDate
                            });
                            setdateTargetDob(dayjs(parsedDate));
                            validationStatus = {...validationStatus, dob: true};

                        }
                        break;
                    case 'admissionDate':
                        if (value !== null) {
                            const day = value.split('.')[0];
                            const month = value.split('.')[1];
                            const year = value.split('.')[2];
                            const parsedDate = new Date(`${year}-${month}-${day}`);
                            dispatchPatient({
                                type: 'SET_FIELD',
                                fieldName: 'admissionDate',
                                payload: parsedDate
                            });
                            setdateTargetAdmission(dayjs(parsedDate));
                            validationStatus = {...validationStatus, admissionDate: true};
                        }

                        break;
                    case 'InsuranceCo':
                        if (value !== null) {
                            dispatchPatient({
                                type: 'SET_FIELD',
                                fieldName: 'InsuranceCo',
                                payload: LDMInsuranceCoMap[value],
                            });
                            validationStatus = {...validationStatus, InsuranceCo: true};
                        }
                        break;
                    case 'franchise':
                        console.log(key, value, LDMFranchiseMap[value]);
                        if (value !== null) {
                            dispatchPatient({
                                type: 'SET_FIELD',
                                fieldName: 'franchise',
                                payload: LDMFranchiseMap[value],
                            });
                            validationStatus = {...validationStatus, franchise: true};
                        }
                        break;

                    default:
                        if (value !== null) {
                            if (key === 'LDMCaseNo'){
                                validationStatus = {...validationStatus, LDMCaseNo: true};
                            } else if (key === 'policyNo'){
                                validationStatus = {...validationStatus, policyNo: true};
                            }
                            dispatchPatient({
                                type: 'SET_FIELD',
                                fieldName: key,
                                payload: value,
                            });
                        }

                        break;
                }

            })

            if (Object.values(validationStatus).every(value => value === true)) {
                setsnackbarStatus({ open: true, message: 'Data Parsed Successfully', severity: 'success' });
            } else if (Object.values(validationStatus).every(value => value === false)) {
                setSubmissionState(true);
                setsnackbarStatus({ open: true, message: 'Invalid Data', severity: 'error' });
            } else if (Object.values(validationStatus).some(value => value === false)) {
                const missingData = Object.keys(validationStatus).filter(key => validationStatus[key] === false);
                const keyStringMap = {
                    name: 'Name',
                    dob: 'Date of Birth',
                    admissionDate: 'Admission Date',
                    InsuranceCo: 'Insurance Company',
                    franchise: 'Franchise',
                    policyNo: 'Policy Number',
                    LDMCaseNo: 'LDM Case Number'
                };
                const missingDataString = missingData.map(data => keyStringMap[data]).join(', ');
                setSubmissionState(true);
                setsnackbarStatus({ open: true, message: `Missing Data: ${missingDataString}`, severity: 'warning' });
            }

        });
    };

    const isLDM = InsuranceCompanyListData?.data?.find((insCo) => insCo.value === patientForm.InsuranceCo)?.type === 'LDM'

    return (<div className='HotelEditFormContainer'>

        <div className='HotelEditFormTitle'>
            <Typography variant='h1' sx={{ fontSize: '1.5rem', color: 'white', fontWeight: 'bolder' }}>
                New Patient Form
            </Typography>

        </div>

        <div className='CreatePatientFormWrapper'>
            <div className='HotelEditFormGroup'>
                <div className='form-row PatientFormRow' style={{ width: '100%', justifyContent: 'flex-start' }}>
                    {HospitalUserIDData && <FormField
                        id="outlined-select-contractedHosp"
                        select
                        label="Hospital/Clinic"
                        value={patientForm.userIDInsert}
                        onChange={handleFieldChange('userIDInsert')}
                        error={submissionState && patientForm.userIDInsert === ''}
                        SelectProps={{
                            MenuProps: {
                                background: 'red',
                                PaperProps: {
                                    sx: { minWidth: '500px !important' }
                                }
                            }
                        }}
                    >
                        <MenuItem sx={{ width: '100%', maxWidth: '500px' }} value={''}>None</MenuItem>
                        {HospitalUserIDData?.data?.sort(
                            (a, b) => -b.Name.localeCompare(a.Name))
                            .map((option) => (
                                <MenuItem sx={{ width: '100%', maxWidth: '500px' }} key={`Cont-Hosp-userID-${option.userID}`} value={option.userID}>
                                    <Typography>{option.Name}</Typography>
                                </MenuItem>
                            ))}
                    </FormField>}

                    <FormField
                        id="outlined-select-insCo"
                        select
                        label="Insurance Company"
                        value={patientForm.InsuranceCo}
                        onChange={handleFieldChange('InsuranceCo')}
                        defaultValue=""
                        error={submissionState && patientForm.InsuranceCo === ''}
                        helperText={isLDM ? 'LDM Insurance Company' : 'RBK Insurance Company'}
                        sx={{ width: '100%', '& .MuiSelect-select': { display: 'flex', gap: '1rem', justifyContent: 'space-between' } }}
                    >
                        {InsuranceCompanyListData?.data?.map((option) => (
                            <MenuItem key={option.value} value={option.value} sx={{ gap: '1rem', justifyContent: 'space-between' }}>
                                <Typography>{option.label}</Typography>
                                <Chip key={`${option.value}-chip`} variant="outlined"
                                    sx={{
                                        border: '1px solid white',
                                        color: 'white',
                                        '& .MuiChip-label': { paddingLeft: 2, paddingRight: 2 },
                                        background: option.type === 'RBK' ? 'rgb(157, 45, 32)' : 'rgb(49,80,125)',
                                        fontWeight: 900, fontSize: '14px',
                                    }}
                                    label={option.type} />
                            </MenuItem>
                        ))}
                    </FormField>

                </div>
                <div className='form-row PatientFormRow'>


                    <FormField
                        label={"Policy Number"}
                        value={patientForm.policyNo}
                        onChange={handleFieldChange('policyNo')}
                        error={submissionState && patientForm.policyNo === ''}
                    />

                    <FormField
                        label={"Residency / City"}
                        value={patientForm.residency}
                        onChange={handleFieldChange('residency')}

                    />

                </div>
                {isLDM && <div className='form-row PatientFormRow'>
                    <FormField

                        label={"LDM Ref Number"}
                        value={patientForm.LDMCaseNo}
                        onChange={handleFieldChange('LDMCaseNo')}
                        error={isLDM && submissionState && patientForm.LDMCaseNo === ''}
                    />
                </div>}
                <div className='form-row PatientFormRow'>

                    <FormField
                        label={"Name"}
                        value={patientForm.name}
                        onChange={handleFieldChange('name')}
                        error={submissionState && patientForm.name === ''}
                    />

                    <InputMask
                        mask="+(999) 999 99 99"
                        value={patientForm.mobilePhone}
                        onChange={handleFieldChange('mobilePhone')}
                    >
                        {() => <FormField label="Mobile Phone" />}
                    </InputMask>
                </div>
                <div className='form-row PatientFormRow'>
                    <FormField
                        id="outlined-select-currency"
                        select
                        label="Franchise"
                        value={patientForm.franchise}
                        onChange={handleFieldChange('franchise')}
                        error={submissionState && patientForm.franchise === ''}
                        helperText="Franchise Amount"
                    >

                        {currencies?.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                <Typography>{option.label}</Typography>
                            </MenuItem>
                        ))}
                    </FormField>


                    <FormField
                        label={"Hotel Room"}
                        value={patientForm.hotelRoom}
                        onChange={handleFieldChange('hotelRoom')} />
                </div>
                <div className='form-row PatientFormRow'>
                    <FormField
                        label="Notes"
                        multiline
                        rows={2}
                        value={patientForm.notes}
                        onChange={handleFieldChange('notes')} />
                    <FormField
                        label="Complaints"
                        multiline
                        rows={2}
                        value={patientForm.complaints}
                        onChange={handleFieldChange('complaints')} />
                </div>
                <div className='form-row PatientFormRow' style={{ justifyContent: 'space-between' }}>
                    <DatePicker
                        label="Date of Birth"
                        value={dateTargetDob}
                        sx={{ background: 'linear-gradient(to bottom, rgb(39, 43, 49), rgb(28, 29, 31) 125%)' }}
                        onChange={(date) => setdateTargetDob(date)}
                        renderInput={(props) => <FormField {...props} />} />
                    <DatePicker
                        label="Admission Date"
                        sx={{ background: 'linear-gradient(to bottom, rgb(39, 43, 49), rgb(28, 29, 31) 125%)' }}
                        value={dateTargetAdmission}
                        onChange={(date) => setdateTargetAdmission(date)}
                        renderInput={(props) => <FormField  {...props} />} />
                </div>


                {!isLDM && <div className='form-row PatientFormRow' style={{ justifyContent: 'space-between', gap: '0rem' }}>
                    <DatePicker
                        label="Policy Start Date"
                        value={dateTargetPolicyStart}
                        sx={{ background: 'linear-gradient(to bottom, rgb(39, 43, 49), rgb(28, 29, 31) 125%)' }}
                        onChange={(date) => setdateTargetPolicyStart(date)}
                        renderInput={(props) => <FormField {...props} />} />


                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                        <ArrowRightIcon sx={{ color: 'white', fontSize: '30px' }} />
                    </div>
                    <DatePicker
                        label="Policy End Date"
                        sx={{ background: 'linear-gradient(to bottom, rgb(39, 43, 49), rgb(28, 29, 31) 125%)' }}
                        value={dateTargetPolicyEnd}
                        onChange={(date) => setdateTargetPolicyEnd(date)}
                        renderInput={(props) => <FormField  {...props} />} />
                </div>}



                <div className='form-row PatientFormRow' style={{ marginTop: '50px', justifyContent: 'space-between' }}>
                    <Button sx={{ background: 'rgb(49,80,125)', color: 'white', padding: '5px 15px 5px 15px', width: '150px', fontWeight: 'bold' }}
                        onClick={handleSubmitPatient}>
                        Submit
                    </Button>
                    <Button sx={{
                        background: 'rgb(40, 49, 65)', color: 'white', gap: '0.5rem', border: '1px solid #42a3ef',

                        fontSize: '1.25rem',
                        padding: '5px 25px 5px 25px', fontWeight: 'bold',  borderRadius: '2px',
                        '&:hover': { background: 'rgb(57, 75, 106)' }
                    }}
                        onClick={handlePasteClipboard}
                    >
                        <ContentPasteIcon sx={{ color: 'white', fontSize: '1.5rem' }} />
                        <Typography sx={{ fontWeight: 'bold' }}>{TextLanguage["PasteLDMForm"][lang]}</Typography>

                    </Button>
                </div>
            </div>
        </div>



    </div>);
}
